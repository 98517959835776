import { Helmet } from 'react-helmet-async';
import FeedbackRegistration from 'src/sections/HSSF/feedback/feedback-registration/feedback-registration';

const FeedbackRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : FEEDBACK REGISTRATION</title>
    </Helmet>

    <FeedbackRegistration />
  </>
);

export default FeedbackRegistrationMain;
