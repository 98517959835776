import { Helmet } from 'react-helmet-async';
  import RangoliSpardhaRegistration from 'src/sections/HSSF/rangoli-spardha/rangoli-spardha-registration/rangoli-spardha-registration';

const RangoliSpardhaRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : RANGOLI SPARDHA REGISTRATION</title>
    </Helmet>

    <RangoliSpardhaRegistration />
  </>
);

export default RangoliSpardhaRegistrationMain;
