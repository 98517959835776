import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import AuthClassicLayout from 'src/layouts/auth/classic';
import AddFamilyRegistrationMain from 'src/pages/HSSF/family/add-family-registration';
import FamilyRegistrationMain from 'src/pages/HSSF/family/family-registration';

import { paths } from '../paths';

export const hssfFamilyRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.familyRegistration.root,
        children: [
          {
            element: (
              <AuthClassicLayout>
                <FamilyRegistrationMain />
              </AuthClassicLayout>
            ),

            index: true,
          },
        ],
      },
      {
        path: paths.hssfPaths.familyRegistration.add,
        element: <AddFamilyRegistrationMain />,
      },
    ],
  },
];
