import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouter } from 'src/routes/hooks';

export default function PrivacyPolicy() {
  useEffect(() => {
    window.location = 'privacy-policy.html';
  }, []);

  return (
    <>
      <Helmet>
        <title> privacy-police</title>
      </Helmet>
    </>
  );
}
