import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import KalashyatraRegistrationMain from 'src/pages/HSSF/kalashyatra/kalashyatra-registration/kalashyatra-registration-main';

import { paths } from '../paths';

export const hssfKalashyatraRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.kalashyatraRegistration,
        element: <KalashyatraRegistrationMain />,
      },
    ],
  },
];
