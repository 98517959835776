import { Helmet } from 'react-helmet-async';
import KalashyatraRegistration from 'src/sections/HSSF/kalashyatra/kalashyatra-registration/kalashyatra-registration';

const KalashyatraRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : KALASHYATRA REGISTRATION</title>
    </Helmet>

    <KalashyatraRegistration />
  </>
);

export default KalashyatraRegistrationMain;
