import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { dropDownLabelValue_ } from 'src/utils/utils';



export default function PrimarySchoolForm({jillaOptions, talukaOptionsOld, clearErrors, setValue}) {
  const [talukaOptions, setTalukaOptions] = useState(dropDownLabelValue_(talukaOptionsOld));
  return (
    <>
      <Grid item xs={12} my={2}>
        <Typography variant="h4" fontWeight={800}>
          Primary School
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.name" label="Primary School Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.udise"
            label="Primary School UDISE number"
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField
            name="primarySchool.address"
            label="Primary School Address"
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField type="number" name="primarySchool.pincode" label="Primary School Pincode" />
        </Grid>
       
        <Grid item xs={12} sm={6}>
          <RHFAutocomplete
            name="primarySchool.district"
            label="Primary School Jilla"
            options={jillaOptions}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(e, val) => {
              clearErrors('primarySchool.district');
              if (val) {
                const filteredTaluka = talukaOptionsOld?.filter((taluka) => taluka?.districtId === val?.value) || [];
                setTalukaOptions(dropDownLabelValue_(filteredTaluka));
                setValue('primarySchool.district', val);
                setValue('primarySchool.taluka', null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFAutocomplete name="primarySchool.taluka" label="Primary School Taluka" options={talukaOptions} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="primarySchool.phone"
            label="Primary School Phone number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.email"
            label="Primary School Email Address"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.principal" label="Primary School Principal Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="primarySchool.principalPhone"
            label="Primary School Principal Phone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.principalEmail"
            label="Primary School Principal Email Address"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.nodelTeacher"
            label="Primary School Nodel Teacher(PT) Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="primarySchool.nodelTeacherPhone"
            label="Primary School Nodel Teacher(PT) Number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.paycenter"
            label="Primary school Paycenter name"
          />
        </Grid>
          <Grid item xs={12} sm={6}>
          <RHFTextField
            name="primarySchool.paycenterPhone"
            label="Primary school Paycenter Mobile number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.crc" label="Primary School CRC name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.crcPhone" label="Primary School CRC Mobile number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.brc" label="Primary School BRC name" />
          </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="primarySchool.brcPhone" label="Primary School BRC Mobile number" />
        </Grid>
      </Grid>
    </>
  );
}
