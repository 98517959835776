import axios from 'axios';
import { auth } from 'src/utils/axios';
import { ROLES } from 'src/utils/utils';

// get all user data
export const getUserData = async (params) => {
  const { data } = await auth.get(
    `${process.env.REACT_APP_BACKEND_URL}api/users?sort=createdAt&order=desc`,
    {
      params: {
        ...params,
      },
    }
  );
  return data;
};

export const updateUserDataToGoogleSheet = async (params) => {
  try {
    const { data } = await auth.get(
      `${process.env.REACT_APP_BACKEND_URL}api/users/google-sheet/?sort=createdAt&order=desc`,
      {
        params: {
          ...params,
        },
      }
    );
    return data;
  } catch (error) {
    if (error.response?.status === 403) {
      return null;
    }
    throw error;
  }
};

// update user data
export const updateUserData = async (userId, userData) => {
  try {
    const { data } = await auth.put(
      `${process.env.REACT_APP_BACKEND_URL}api/user/${userId}`,
      userData
    );
    return data;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

// forgot password
export const forgotPassword = async (email) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}api/forgot-password`;
    const { data } = await auth.post(url, { email });
    return data;
  } catch (error) {
    console.error('Error in forgot password:', error);
    throw error;
  }
};

// reset password

export const resetPassword = async (resetToken, newPassword) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}api/reset-password/${resetToken}`;
    const { data } = await auth.post(url, { password: newPassword });
    return data;
  } catch (error) {
    console.error('Error in reset password:', error);
    throw error;
  }
};

// delete user
export const deleteUser = async (userId) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/user/${userId}`;
  await axios.delete(url, {
    headers: {
      authorization: localStorage.getItem('accessToken')
        ? `Bearer ${localStorage.getItem('accessToken')}`
        : null,
    },
  });
};

// update user profile
export const updateUserProfile = async (userData) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}api/user/update-profile`;
    const { data } = await auth.patch(url, userData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    const {user} = data;
    const userData_ = {
      ...user,
      role: user?.user_role,
      user_name: `${user.firstName} ${user.lastName}`,
      value: user.user_role === ROLES.superAdmin ? 'prant' : 'vibhag',
    };
    localStorage.setItem('user', JSON.stringify(userData_));
    return data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};
