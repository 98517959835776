import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import DeleteUserMain from 'src/sections/user/delete-user-page';
import AuthClassicLayout from 'src/layouts/auth/classic';
import { paths } from '../paths';

export const deleteUserRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.deleteUser,
        element: (
          <AuthClassicLayout>
            <DeleteUserMain />
          </AuthClassicLayout>
        ),
      },
    ],
  },
];
