import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const getFeedbackList = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/feedbacks`;
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};

export const updateFeedbackToGoogleSheet = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/feedbacks/google-sheet`;
  try {
    const { data } = await axiosInstance.get(url, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return data;
  } catch (error) {
    if (error.response?.status === 403) {
      return null;
    }
    throw error;
  }
};

export const createFeedback = async (payload) => {
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_BACKEND_URL}api/feedbacks`,
    payload,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

export const updateFeedback = async (id, payload) => {
  const { data } = await axiosInstance.put(
    `${process.env.REACT_APP_BACKEND_URL}api/feedbacks/${id}`,
    payload,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

  export const deleteFeedback = async (id) => {
  const { data } = await axiosInstance.delete(
    `${process.env.REACT_APP_BACKEND_URL}api/feedbacks/${id}`,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};
