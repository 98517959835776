import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Divider, FormHelperText, Button, Typography, Grid, Box, Card } from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { getJillaData, getTalukaData } from 'src/api/services/location.services';
import { updateUserProfile } from 'src/api/services/user.services';
import { useAuthContext } from 'src/auth/hooks';
import FormProvider, {
  RHFAutocomplete,
  RHFCheckbox,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { RHFMultiCustomCheckbox } from 'src/components/hook-form/rhf-custom-checkbox';
import RHFRadioCustomGroup from 'src/components/hook-form/rhf-custom-radio-group';
import { useLocales } from 'src/locales';
import { professions_options } from 'src/pages/HSSF/dummy_data';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { fData } from 'src/utils/format-number';
import {
  aayam_types_options,
  dropDownLabelValue_,
  getFairYear,
  interests_options,
  ROLES,
  working_hours_option,
  working_times_option,
} from 'src/utils/utils';
import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required('First name required'),
  lastName: Yup.string().required('Last name required'),
  contact: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .required('Enter Mobile Number')
    .typeError('Please enter a valid Mobile Number.'),
  whatsapp_number: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Whatsapp Number.')
    .required('Enter Whatsapp Number')
    .typeError('Please enter a valid Whatsapp Number.'),
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  address: Yup.string().required('Address is required'),
  pin_code: Yup.string().matches(
    /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
    'Please enter a valid pincode.'
  ),
  profession: Yup.object()
    .test('required', 'Profession is required', (value) => value && value.value !== '')
    .nullable(),
  jilla: Yup.object()
    .test('required', 'District is required', (value) => value && value.value !== '')
    .nullable(),
  taluka: Yup.object()
    .test('required', 'Taluka is required', (value) => value && value.value !== '')
    .nullable(),
  any_other_responsibility: Yup.string().required('Responsibility is required'),
  any_other_option_aayam_dropdown: Yup.object()
    .test('required', 'Activities is required', (value, { parent }) => {
      if (parent.any_other_option_aayam_check) {
        return value && value.value !== '';
      }
      return true;
    })
    .nullable(),
  // other_profession: Yup.string().required('Other Profession is required'),
  aadhar_number: Yup.string()
    .min(12, 'Aadhar number should not less than 12 digits')
    .max(12, 'Aadhar number should not exceed 12 digits')
    .required('Enter Aadhar number')
    .matches(/^[0-9]*$/, 'Please enter only digits.'),
});

const defaultValues = {
  firstName: '',
  lastName: '',
  same_as_whatsapp_number: false,
  contact: '',
  whatsapp_number: '',
  email: '',
  address: '',
  pin_code: '',
  // password: '',
  // confirm_password: '',
  // reference_registration_person_name: authenticated
  //   ? { combine_data_key: '', value: '', guj_combine_data_key: '' }
  //   : { combine_data_key: 'xyz', value: 'xyz', guj_combine_data_key: 'xyz' },
  // reference_registration_person_contact: '',
  profession: { label: '', value: '', guj_label: '' },
  // vibhag: '',
  // jilla: '',
  // taluka: '',
  taluka: { label: '', value: '' },
  jilla: { label: '', value: '' },
  vibhag: { label: '', value: '' },
  other_profession: '',
  any_other_responsibility: '',
  // check-box
  // working_hrs: [],
  interest: [],

  // radio-button
  aayam_types: '',
  any_other_option_aayam_check: false,
  any_other_option_aayam_dropdown: { label: '', value: '', guj_label: '' },
  working_hrs: '',
  // person_type: '',
  working_time: '',
  responsible_for_fair: false,
  aadhar_number: '',
};

export default function ProfileEdit({ userData, setIsEditMode }) {
  const { register, authenticated } = useAuthContext();
  const router = useRouter();
  const location = useLocation();
  const aadharImageRef = useRef(null);
  const { t } = useTranslation();
  const locales = useLocales();

  const [jillaOptions, setJillaOptions] = useState([]);
  const [talukaOptions, setTalukaOptions] = useState([]);
  const [errorss, setError] = useState(false);

  const { currentLang } = useLocales();

  const uploadFile = async (file) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}api/upload`,
          formData
        );
        return data;
      }
      return null;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = methods;
  const values = watch();

  useEffect(() => {
    getJillaData()
      .then((res) => setJillaOptions(dropDownLabelValue_(res.data)))
      .catch((err) => console.error('error', err));
    getTalukaData()
      .then((res) => setTalukaOptions(dropDownLabelValue_(res.data)))
      .catch((err) => console.error('error', err));
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    let responseAadhar = null;
    let responseUserImage = null;

    if (data?.aadhar_image instanceof File) {
      responseAadhar = await uploadFile(data?.aadhar_image);
    }
    if (data?.user_image instanceof File) {
      responseUserImage = await uploadFile(data?.user_image);
    }

    const payload = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      userImage: responseUserImage?.data ? responseUserImage?.data : userData?.userImage,
      email: userData?.email, // Use existing email from userData
      mobileNo: userData?.mobileNo, // Use existing mobile number from userData
      whatsappNo: data?.whatsapp_number,
      address: data?.address,
      pinCode: data?.pin_code,
      profession: data?.profession?.value,
      otherProfession: data?.other_profession,
      jilla: data?.jilla?.value,
      taluka: data?.taluka?.value,
      talukaName: data?.taluka?.label,
      districtName: data?.jilla?.label,
      districtId: data?.jilla?.value,
      talukaId: data?.taluka?.value,
      typesOfActivities: data?.aayam_types,
      otherTypesOfActivities: data?.any_other_option_aayam_check
        ? data?.any_other_option_aayam_dropdown?.value
        : null,
      interest: data?.interest,
      workingPreference: data?.working_time,
      workingHrs: data?.working_hrs,
      anyOtherResponsibility: data?.responsible_for_fair ? 'Yes' : 'No',
      responsibleForFair: data?.responsible_for_fair,
      aadharNumber: data?.aadhar_number.toString(),
      aadharImage: responseAadhar?.data ? responseAadhar?.data : userData?.aadharImage,
    };

    try {
      // Assuming you have an update API function

      await updateUserProfile(payload);

      enqueueSnackbar('Profile Updated Successfully', { variant: 'success' });
      setIsEditMode(false);
    } catch (error) {
      console.error('Error updating profile: ', error);

      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('user_image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleCancelClick = () => {
    setIsEditMode(false);
    reset(); // Reset form to default values or previous values
  };

  const renderContactNumber = (
    <>
      <Divider sx={{ borderStyle: 'dashed', mt: 2.5, mb: 1.5 }} />
      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
        {t('contact_details')} *
      </Typography>
      <Box
        rowGap={2.5}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField disabled name="contact" label={`${t('mobile_number')} *`} />
        <RHFTextField
          disabled={values?.same_as_whatsapp_number}
          name="whatsapp_number"
          label={`${t('user_registration_page.input_field.whatsapp_number')} *`}
        />
      </Box>
      <RHFCheckbox
        name="same_as_whatsapp_number"
        label={t('user_registration_page.input_field.mobile_same_as_whatsapp')}
      />
    </>
  );

  const renderAvatarPhoto = (
    <Grid xs={12} md={4}>
      <RHFUploadAvatar
        isSquare
        name="user_image"
        maxSize={5000000}
        onDrop={handleDrop}
        helperText={
          <Typography
            variant="caption"
            sx={{
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.disabled',
            }}
          >
            Allowed *.jpeg, *.jpg, *.png, *.gif
            <br /> max size of {fData(5000000)}
          </Typography>
        }
      />
    </Grid>
  );

  useEffect(() => {
    if (userData) {
      methods.reset({
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
        contact: userData?.mobileNo || '',
        whatsapp_number: userData?.whatsappNo || '',
        email: userData?.email || '',
        profession:
          professions_options.find((option) => option.value === userData?.profession) || null,
        user_image: userData?.userImage || '',
        address: userData?.address || '',
        pin_code: userData?.pinCode || '',
        taluka: {
          label: userData?.talukaName || '',
          value: userData?.talukaId || '',
        },
        jilla: {
          label: userData?.districtName || '',
          value: userData?.districtId || '',
        },
        // taluka: talukaOptions.find((option) => option.value === userData?.talukaId) || null,
        // jilla: jillaOptions.find((option) => option.value === userData?.districtId) || null,
        aadhar_number: userData?.aadharNumber || '',
        aadhar_image: userData?.aadhar_image || '',
        aayam_types: userData?.typesOfActivities || '',
        interest: userData?.interest || '',
        working_time: userData?.workingPreference || '',
        working_hrs: userData?.workingHrs || '',
        any_other_responsibility: userData?.anyOtherResponsibility || '',
      });

      // Set the checkbox if whatsapp number is same as contact

      if (userData?.mobileNo === userData?.whatsappNo) {
        methods.setValue('same_as_whatsapp_number', true);
      }

      // Set responsible_for_fair based on anyOtherResponsibility value
      if (userData?.anyOtherResponsibility === 'Yes') {
        methods.setValue('responsible_for_fair', true);
      } else {
        methods.setValue('responsible_for_fair', false);
      }
    }
  }, [userData, methods]);

  return (
    <Card sx={{ p: 4 }}>
      <FormProvider methods={methods} onSubmit={onSubmit} sx={{ pb: '80px' }}>
        <Grid container spacing={2.5}>
          <>
            {renderAvatarPhoto}
            <Grid item xs={12} md={8} pb={0}>
              <Box
                rowGap={2.5}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="firstName"
                  label={`${t('user_registration_page.input_field.first_name')} *`}
                />
                <RHFTextField
                  name="lastName"
                  label={`${t('user_registration_page.input_field.last_name')} *`}
                />
              </Box>
              {renderContactNumber}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ borderStyle: 'dashed', mb: 1.5 }} />
            </Grid>
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  disabled
                  name="email"
                  label={`${t('user_registration_page.input_field.email_id')} *`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFAutocomplete
                  name="profession"
                  label={`${t('user_registration_page.input_field.profession')} *`}
                  options={professions_options}
                  getOptionLabel={(option) =>
                    currentLang.value === 'guj' ? option.guj_label : option.label
                  }
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(event, newValue) => {
                    setValue('profession', newValue, {
                      shouldValidate: true,
                    });

                    if (newValue?.value === 'other') {
                      setValue('other_profession', '', {
                        shouldValidate: false,
                      });
                    } else {
                      setValue('other_profession', newValue?.value, {
                        shouldValidate: true,
                      });
                    }
                  }}
                />
              </Grid>
              {values?.profession?.value === 'other' && (
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    name="other_profession"
                    label={`${t('user_registration_page.input_field.other_profession')} *`}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="address"
                  label={`${t('user_registration_page.input_field.address')} *`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="pin_code"
                  label={`${t('user_registration_page.input_field.pin_code')} *`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFAutocomplete
                  name="jilla"
                  label={`${t('jilla')} *`}
                  options={jillaOptions}
                  onChange={(e, val) => {
                    setValue('jilla', val);
                    if (val) {
                      getTalukaData({ districtId: val.value })
                        .then((res) => {
                          const talukaData = dropDownLabelValue_(res.data);
                          setTalukaOptions(talukaData);
                          setValue('taluka', null);
                        })
                        .catch((err) => console.error('Error fetching jilla data:', err));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFAutocomplete name="taluka" label={`${t('taluka')} *`} options={talukaOptions} />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="any_other_responsibility"
                  label={`${t('user_registration_page.input_field.any_other_responsibility')} *`}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  name="aadhar_number"
                  type="number"
                  label={`${t('user_registration_page.input_field.aadhar_number')} *`}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Typography variant="subtitle2">
                  {t('user_registration_page.input_field.aayam_types')}
                </Typography>
                <RHFRadioCustomGroup
                  column
                  name="aayam_types"
                  options={aayam_types_options}
                  guj={locales.currentLang.value === 'guj'}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Typography variant="subtitle2">
                  {t('user_registration_page.input_field.interest')}
                </Typography>
                <RHFMultiCustomCheckbox
                  column
                  name="interest"
                  options={interests_options}
                  guj={locales.currentLang.value === 'guj'}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Typography variant="subtitle2">
                  {t('user_registration_page.input_field.working_time')}
                </Typography>
                <RHFRadioCustomGroup
                  column
                  name="working_time"
                  options={working_times_option}
                  guj={locales.currentLang.value === 'guj'}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Typography variant="subtitle2">
                  {t('user_registration_page.input_field.working_hrs')}
                </Typography>
                <RHFRadioCustomGroup
                  column
                  name="working_hrs"
                  options={working_hours_option}
                  guj={locales.currentLang.value === 'guj'}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFCheckbox
                  name="responsible_for_fair"
                  label={`${t(
                    'user_registration_page.input_field.responsible_for_fair'
                  )} - ${getFairYear()}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{t('aadhar_image')}</Typography>
                <input
                  type="file"
                  name="aadhar_image"
                  accept="application/pdf"
                  ref={aadharImageRef}
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
                      if (file.size < maxSize) {
                        setError(false);
                        setValue('aadhar_image', file, { shouldValidate: true });
                      } else {
                        setError(true);
                        event.target.value = '';
                      }
                    }
                  }}
                />
                {errorss && (
                  <FormHelperText error sx={{ px: 2, textAlign: 'start' }}>
                    File is larger than 5000000 bytes
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <LoadingButton
                  sx={{ maxWidth: 'fit-content', mr: { xs: 1, sm: 2 } }}
                  color="inherit"
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t('update_profile')}
                </LoadingButton>

                <Button onClick={handleCancelClick} variant="outlined" size="large">
                  {t('cancel')}
                </Button>
              </Grid>
            </Grid>
          </>
        </Grid>
      </FormProvider>
    </Card>
  );
}
