import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { ROLES } from 'src/utils/utils';
import { paths } from 'src/routes/paths';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import NewRegistrationForm from './new-registration-form';

export default function AddRegistrationView() {
  const { t } = useLocales();
  const settings = useSettingsContext();
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Typography
        variant="h4"
        sx={{
          p: 2,
          display: 'none',
        }}
      >
        The registration for Sanstha is now closed.
      </Typography>
      <Box sx={{}}>
        <CustomBreadcrumbs
          heading={t('new_registration.registration_title')}
          links={[
            {
              name: t('dashboard'),
              href:
                user?.user_role === ROLES.superAdmin
                  ? paths.hssfPaths.root
                  : paths.hssfPaths.registration.list,
            },
            {
              name: t('new_registration.registration_title'),
              href: paths.hssfPaths.registration.list,
            },
            {
              name: t('registration'),
              href: paths.hssfPaths.registration.list,
            },
          ]}
          sx={{ mb: { xs: 3, md: 5 }, my: 2.5 }}
        />
        <NewRegistrationForm />
      </Box>
    </Container>
  );
}
