import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRouter } from 'src/routes/hooks';

export default function CheckSessionPage() {
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      window.location = 'hssf';
    } else {
      window.location = 'spiritual/index.html';
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Checksession</title>
      </Helmet>
    </>
  );
}
