import axios from 'axios';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

export const getRangoliSpardhaList = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/rangoli-spardha-registrations`;
  const { data } = await axiosInstance.get(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  });
  return data;
};

export const updateRangoliSpardhaToGoogleSheet = async () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/rangoli-spardha-registrations/google-sheet`;
  try {
    const { data } = await axiosInstance.get(url, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    return data;
  } catch (error) {
    if (error.response?.status === 403) {
      return null;
    }
    throw error;
  }
};

export const createRangoliSpardha = async (payload) => {
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_BACKEND_URL}api/rangoli-spardha-registrations`,
    payload,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

export const updateRangoliSpardha = async (id, payload) => {
  const { data } = await axiosInstance.put(
    `${process.env.REACT_APP_BACKEND_URL}api/rangoli-spardha-registrations/${id}`,
    payload,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};

  export const deleteRangoliSpardha = async (id) => {
  const { data } = await axiosInstance.delete(
    `${process.env.REACT_APP_BACKEND_URL}api/rangoli-spardha-registrations/${id}`,
    {
      headers: {
        authorization: localStorage.getItem('accessToken')
          ? `Bearer ${localStorage.getItem('accessToken')}`
          : null,
      },
    }
  );
  return data;
};
