import { Helmet } from 'react-helmet-async';
import PrabandhakRegistration from 'src/sections/HSSF/prabandhak/prabandhak-registration/prabandhak-registration';

const PrabandhakRegistrationMain = () => (
  <>
    <Helmet>
      <title> HSSF : PRABANDHAK REGISTRATION</title>
    </Helmet>

    <PrabandhakRegistration />
  </>
);

export default PrabandhakRegistrationMain;
