// import noAuthAxios from 'src/utils/noAuthAxios';

import axios from 'src/utils/axios';

// get all prant data
// export const getPrantData = async () => {
//   const url = API_END_POINTS.PRANTS;
//   const { data } = await axios.get(url);
//   return data;
// };

// get all Vibhag data
export const getVibhagData = async (params) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/vibhags`;
  const { data } = await axios.get(url);
  return data;
};

// get all Jilla data
export const getJillaData = async (params) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/districts`;
  const { data } = await axios.get(url, { params: { ...params }, headers: { Authorization: '' } });
  return data;
};

// get all Taluka data
export const getTalukaData = async (params) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}api/talukas`;
  const { data } = await axios.get(url, { params: { ...params }, headers: { Authorization: '' } });
  return data;
};
