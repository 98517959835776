/* eslint-disable react-hooks/exhaustive-deps */
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { paths } from 'src/routes/paths';
import PrabandhakRegistrationForm from './prabandhak-registration-form';

export default function PrabandhakRegistration() {
  const settings = useSettingsContext();
  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('prabandhak_registration')}
        links={[
          { name: t('dashboard'), href: paths.hssfPaths.root },
          {
            name: t('prabandhak_management'),
            href: paths.hssfPaths.prabandhak.management.root,
          },
          { name: t('prabandhak_registration') },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      {/* Form Page */}
      <Card sx={{ p: 4 }}>
        <PrabandhakRegistrationForm />
      </Card>
    </Container>
  );
}
