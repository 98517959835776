import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import FormProvider, {
  RHFTextField,
  RHFRadioGroup,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { createKalashyatra } from 'src/api/services/kalashyatra.services';  
import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  attendanceType: Yup.string().required('Please select how you plan to attend'),
  ngoName: Yup.string().when('attendanceType', {
    is: 'organization',
    then: () => Yup.string(),
    otherwise: () => Yup.string(),
  }),
  mobileNo: Yup.string()
    .matches(/^[6-9][0-9]{9}$/, 'Please enter a valid Mobile Number.')
    .nullable(),
  email: Yup.string()
    .email('Must be a valid email')
    .nullable(),
});

const defaultValues = {
  name: '',
  attendanceType: 'individual',
  ngoName: '',
  mobileNo: '',
  email: '',
};

const attendanceOptions = [
  { value: 'individual', label: 'Individual' },
  { value: 'group', label: 'In Group' },
  { value: 'organization', label: 'As an NGO/Organization' },
];

export default function KalashyatraRegistrationForm() {
  const navigate = useNavigate();
  const router = useRouter();
  const location = useLocation();
  const { t } = useLocales();

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        name: data.name,
        attendAs: data.attendanceType,
        ngoName: data.ngoName,
        mobileNumber: data.mobileNo,
        email: data.email,
      };

      await createKalashyatra(payload);

      if (location.pathname !== paths.auth.jwt.register) {
        router.push(paths.hssfPaths.kalashyatra.management.root);
      }
      enqueueSnackbar('Registration Successfully');
      reset();
    } catch (error) {
      console.error('Error: ', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } else {
        enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      }
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <RHFTextField
            name="name"
            label="Name *"
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
            Planning to attend as *
          </Typography>
          <RHFRadioGroup
            name="attendanceType"
            options={attendanceOptions}
          />
        </Grid>

        {values.attendanceType === 'organization' && (
          <Grid xs={12}>
            <RHFTextField
              name="ngoName"
              label="NGO/Organization Name (Optional)"
              fullWidth
            />
          </Grid>
        )}

        <Grid xs={12}>
          <RHFTextField
            name="mobileNo"
            label="Mobile No."
            type="number"
            inputProps={{ maxLength: 10 }}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 10);
            }}
            fullWidth
          />
        </Grid>

        <Grid xs={12}>
          <RHFTextField
            name="email"
            label="Email (Optional)"
            fullWidth
          />
        </Grid>

        <Grid xs={12} textAlign="end" display="flex" justifyContent="flex-end" gap={2}>
          <LoadingButton
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('register')}
          </LoadingButton>
          <Button
            sx={{ maxWidth: 'fit-content' }}
            color="inherit"
            size="large"
            variant="outlined"
            onClick={() => {
              methods.reset();
              navigate(paths.hssfPaths.kalashyatra.management.root);
            }}
          >
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
