import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

import RangoliSpardhaRegistrationMain from 'src/pages/HSSF/rangoli-spardha/rangoli-spardha-registration/rangoli-spardha-registration-main';

import { paths } from '../paths';

export const hssfRangoliSpardhaRegistrationRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        path: paths.hssfPaths.rangoliSpardhaRegistration,
        element: <RangoliSpardhaRegistrationMain />,
      },
    ],
  },
];
