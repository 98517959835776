import axios from 'axios';

// Helper function to get token from local storage
const getToken = () => localStorage.getItem('accessToken');

export async function getPrantTeams(vibhagId, districtId) {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/prant-teams`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getAllPrantTeamsWithActivities() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}api/prant-teams-members`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getTeams({ vibhagId, districtId, talukaId }) {
  try {
    let queryString = '';
    if (vibhagId && districtId) {
      queryString = `vibhagId=${vibhagId}&districtId=${districtId}`;
    } else if (vibhagId) {
      queryString = `vibhagId=${vibhagId}`;
    } else if (districtId) {
      queryString = `districtId=${districtId}`;
    } else if (talukaId) {
      queryString = `talukaId=${talukaId}`;
    }
    const url = `${process.env.REACT_APP_BACKEND_URL}api/teams/search${
      queryString ? `?${queryString}` : ''
    }`;
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getAllVibhag() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/vibhag`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getDistrictsByVibhagId(vibhagId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}api/districts-by-vibhag`,
      {
        params: {
          vibhagId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getTalukasByDistrictId(districtId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}api/talukas-by-district`,
      {
        params: {
          districtId,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function assignTeamMember(data) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/team/member`, data, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function removeTeamMember(memberId) {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}api/team/member/remove/${memberId}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getActivities() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/activities`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}

export async function getSamuh() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/samuh`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
} 

export async function getKaryaVibhags() {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}api/karyavibhag`);
    return response.data;
  } catch (error) {
    throw new Error(error.response ? error.response.data.message : error.message);
  }
}
