import React, { useState }   from 'react';
import { Grid, Typography } from '@mui/material';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { dropDownLabelValue_ } from 'src/utils/utils';

export default function SecondarySchoolForm({jillaOptions, talukaOptionsOld, clearErrors, setValue}) {
  const [talukaOptions, setTalukaOptions] = useState(dropDownLabelValue_(talukaOptionsOld));
  return (
    <>
      <Grid item xs={12} my={2}>
        <Typography variant="h4" fontWeight={800}>
          Secondary/Higher Secondary School
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.name" label="Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="secondarySchool.ssindex"
            label="SSCINDEX number"
            inputProps={{ placeholder: 'e.g., 22.4444' }}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFTextField
            name="secondarySchool.address"
            label="Address"
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.pincode"
            label="Pincode"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFAutocomplete
            name="secondarySchool.district"
            label="Secondary School Jilla"
            options={jillaOptions}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onChange={(e, val) => {
              clearErrors('secondarySchool.district');
              if (val) {
                const filteredTaluka = talukaOptionsOld?.filter((taluka) => taluka?.districtId === val?.value) || [];
                setTalukaOptions(dropDownLabelValue_(filteredTaluka));
                setValue('secondarySchool.district', val);
                setValue('secondarySchool.taluka', null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
            <RHFAutocomplete name="secondarySchool.taluka" label="Secondary School Taluka" options={talukaOptions} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.phone"
            label="Phone number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.email" label="Email Address" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.principal" label="Principal Name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.principalPhone"
            label="Principal Phone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="secondarySchool.principalEmail"
            label="Principal Email Address"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            name="secondarySchool.nodelTeacher"
            label="Nodel Teacher(PT) Name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField
            type="number"
            name="secondarySchool.nodelTeacherPhone"
            label="Nodel Teacher(PT) Number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.svsc" label="SVS School name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.svscPhone" label="SVS School Mobile number" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.qdc" label="QDC School name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RHFTextField name="secondarySchool.qdcPhone" label="QDC School Mobile number" />
        </Grid>
      </Grid>
    </>
  );
}
